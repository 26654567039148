angular.module('monjApp').value('appSettings', {
    title: 'Monj Application',
    version: '1.0',

    apiKey: "AIzaSyAttBW9WL81VZ3rf_gewbGchzYw7NxYSb4",
    authDomain: "monj-test.firebaseapp.com",
    databaseURL: "https://monj-test.firebaseio.com",
    projectId: "monj-test",
    storageBucket: "monj-test.appspot.com",
    messagingSenderId: "231241138150",

    contentfulAccessToken: '4721617a86b4c993e1c0e8cdbac28cec2fa8ffc91495e44a30dc637b430d7a37',
    contentfulHost: 'preview.contentful.com',
    contentfulSpace: '5u7jt849cgc0',

    monjApiKey: '1',
    monjURL: 'https://apitest.monj.com/v1',
    monjUploadURL: 'https://apitest.monj.com',

    segmentKey: 'eYPIDU2aZbtZt8p5Sx0SWFQe7C2TshwS',

    pageTitle: ' - [TEST]',

    isProduction: false
});
